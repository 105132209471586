<template>
    <v-container id="terms">
        <v-row>
            <v-col cols="10">
                <h1 class="text-h2">Terms of Service</h1>

                <h5 class="text-h6">Last updated on 18/01/2023</h5>

                <h2 class="text-h5">Welcome to {{ constants.app_name }}</h2>

                <p class="text-body-1">
                    These terms of service outline the rules and regulations for the use of Network Black Hole Website.
                </p>

                <p>
                    {{ constants.app_name }} is a service offered by <a href="https://www.smartnetworkingsolutions.uk">
                    SMART NETWORKING SOLUTIONS LTD</a>.
                </p>

                <p>
                    SMART NETWORKING SOLUTIONS LTD is a private company registered in the United Kingdom, located at:
                </p>

                <ul class="address">
                    <li><strong>Smart Networking Solutions Ltd</strong></li>
                    <li>27 Old Gloucester Street</li>
                    <li>London WC1N 3AX</li>
                    <li>United Kingdom</li>
                </ul>

                <p>
                    By accessing this website we assume you accept these terms of service in full. Do not continue to
                    use Network Black Hole website if you do not accept all of the terms of service stated
                    on this page.
                </p>

                <p>
                    The following terminology applies to these Terms of Service, Privacy Statement and Disclaimer Notice
                    and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this
                    website and accepting the Company's terms of service. "The Company", "Ourselves", "We", "Our" and
                    "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves,
                    or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of
                    payment necessary to undertake the process of our assistance to the Client in the most appropriate
                    manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose
                    of meeting the Client's needs in respect of provision of the Company's stated services/products, in
                    accordance with and subject to, prevailing law of United Kingdom. Any use of the above terminology
                    or other words in the singular, plural, capitalisation and/or he/she or they, are taken as
                    interchangeable and therefore as referring to same.
                </p>

                <h2>Cookies</h2>
                <p>
                    We employ the use of cookies. By using Network Black Hole website you consent to the
                    use of cookies in accordance with Network Black Hole's privacy policy.
                </p>

                <p>
                    Most of the modern day interactive web sites use cookies to enable us to retrieve user details for
                    each visit. Cookies are used in some areas of our site to enable the functionality of this area and
                    ease of use for those people visiting. We do not use any affiliate / advertising partners and no 3rd
                    party cookies are used.
                </p>

                <h2>Using our Service</h2>
                Using our Service does not give you ownership of any intellectual property rights in our Service or the content you access.

                <h2>Description of Service</h2>
                Network Black Hole provides you with access to a tool that allows for blocking computer network traffic, inbound and/or outbound, depending on how it is used. You also understand that the Service may include certain communications from Network Black Hole, such as service announcements and administrative messages, that are related to the provision of the Service. Unless explicitly stated otherwise, any new features or tools released by us in relation to the Service shall be subject to this Agreement.

                The Service is an online product that requires access via the Internet or other network connection. You are responsible for obtaining the necessary internet or network connection to access Network Black Hole Service, and you are responsible for the associated third-party fees (such as internet service provider or airtime charges) for the connectivity to the Service. Additionally, you are responsible for procuring and maintaining all equipment necessary to connect to the Service. The quality of the Service that you experience may depend on your connectivity and equipment. Network Black Hole is not responsible for any claims or losses that arise as a result of your connectivity and equipment.

                <h2>Your Registration Obligations</h2>
                As a registered user of Network Black Hole Service, you represent that you are of legal age to form a binding contract. You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form (the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Network Black Hole has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Network Black Hole has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).

                <h2>No Resale or Redistribution of Service</h2>
                You are permitted to use the Service internally within the company at any number of geographical locations.

                Except as expressly authorized by Network Black Hole, you agree not to reproduce, duplicate, copy, sell, trade, resell, permit access, transfer, assign, modify, create derivative works, or exploit for any commercial purposes, any portion of the Service, use of the Service, or access to the Service or computer code that powers the Service.

                You are responsible for all use made of the Service using your account. You are responsible for maintaining the confidentiality of your online account credentials.

                <h2>Account Access</h2>
                You must provide your valid email address, and any other information requested, in order to complete the registration process and become a registrant. Your login may only be used by a single user. A single login shared by multiple users is not permitted. We reserve the right to cancel or suspend your access to the Service if we see any suspicious activity in your account that reasonably points out you willingly and/ or knowingly shared your credentials with another user. You are responsible for maintaining the security of your account and password. Network Black Hole cannot and will not be liable for any loss or damage from your failure to comply with this obligation.

                <h2>Unauthorized Users</h2>
                Account registration must be completed by a human. Accounts registered by "bots" or other automated methods are not permitted.

                <h2>Unauthorized Uses</h2>
                You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service or Network Black Hole. You may not reverse engineer object code or reuse source code provided in relation to the Service. This includes any and all JavaScript. The code is Network Black Hole copyright. You shall not transmit any worms or viruses or any code of a destructive or malicious nature. You agree not to modify any code provided in relation to the Service in any manner or form, nor to use modified versions of such code, including (without limitation) for the purpose of obtaining unauthorized access to the Service. You agree not to access the Service by any means other than through the interface that is provided by Network Black Hole for use in accessing the Service, and shall not interfere or disrupt the integrity, security, functionality or performance of the Service or its components. All unnatural means or unlawful attempts to access our Service, website, server or database including by means of any automatic tools such as scrapers or bots is prohibited. We may terminate, suspend and/or block your access if we determine that your use or access of our Service violates our terms. Subject to the other restrictions stated herein, you may only use automated tools to access our Service through the Application Program Interfaces (API) that we provide.

                You may not attempt to bypass any functionality or access features on the website directly by using custom http (or other) calls, or attempt to manipulate the Service, or access it in a manner other than in a way in which a reasonable person would consider normal permitted use.

                You should not attempt to take advantage of any feature that a reasonable person would consider an obvious omission or error on the Service and website.

                Network Black Hole may issue instructions, rules, guidelines and directions from time to time in respect of its provision of the Service, which shall be complied with by you.

                <h2>Blocking of IP Addresses</h2>
                In order to protect the integrity of the Service, Network Black Hole reserves the right at any time in its sole discretion to block registrants and subscribers from certain IP addresses from accessing the Service.

                <h2>Registration, Subscription, Cancellation by You and Additional Usage (Pay-As-You-Go)</h2>
                You may become a registrant of the Service at no cost. As a registrant, you will have the ability to participate in some, but not all, of the features and tools available within the Service.

                In order to access additional features, including the ability to use many of our tools, you must become a subscriber to the Service.

                We offer paid monthly subscription plans and annual subscription plans. These subscriptions are paid upfront monthly or annually depending on your subscription plan and according to your billing period, with automatic renewals every month or year respectively. You therefore acknowledge and agree that there will be recurring payments by you for the same and that we are authorised to charge the same credit card that you have used for the initial subscription and/or any other payment method used. This will continue until you cancel your subscription plan with us. Such cancellation must be made by you before your subscription renews in order to avoid being charged for the following month’s or year’s subscription fee. When you cancel, you will still have access to our Service based on your subscription plan until the end of your subscription period. You may also opt to downgrade your subscription plan at any time. Any downgrade will only take effect after the end of your current subscription period and the provisions in this section will continue to apply to such downgraded subscription plans.

                Depending on the subscription plan you select, you will be entitled to varying number of features and/or different limits. Any additional usage on top of those provided under the respective subscription plans is subject to additional charges as set forth in our pricing page. We will periodically charge you for such usage on a pay-as-you-go basis. This will continue for any accrued pay-as-you-go usage even after the cessation of your subscription plan for any reason and any unused portion will expire by the stipulated timeframe without being transferred to any subsequent usage period. In the event that such charges are not paid by you, we shall have the right to remove your access to our Service at any time and apply the value of your remaining subscription plan towards the fulfilment of such charges.

                <h2>Refund policy</h2>
                Unless required by law, Network Black Hole is not obligated to provide you a refund at any time or for any reason. For your monthly subscription, you may request for a refund after payment has been made for non-use, but please be advised that we reserve the right to decline your refund request should we see any material activity in your account.

                <h2>Cancellation of Service by Network Black Hole</h2>
                In addition to the above, Network Black Hole reserves the right of unilateral service cancellation under the following circumstances:

                You are in breach of any of these terms.

                We detect unusual usage patterns or unauthorized uses in relation to the Service.

                You attempt to inflict damage or harm our reputation, work or Service.

                In response to requests from law enforcement or other public agencies.

                You become a subject of a petition in a bankruptcy or any other proceeding relating to insolvency, cessation of business, liquidation or assignment for the benefit of creditors.

                <h2>Data Collection and Use</h2>
                You understand and agree that Network Black Hole may anonymize and aggregate any data you share with the Service, including data associated with your Google Analytics and Google Search Console accounts, and use it without identifying you for purposes of improving the Service.

                <h2>Modifications to Service</h2>
                We are constantly changing and improving our Service. We may add or remove functionalities or features, and we may suspend or stop the Service altogether. You can stop using our Service at any time, although we'll be sorry to see you go. Network Black Hole may also stop providing the Service to you, or add or create new limits to our Service at any time.

                You agree that Network Black Hole shall not be liable, to you or to any third party, for any modification, suspension or discontinuance of the Service.

                Network Black Hole reserves the right to amend and change any subscription plan and/or adjust the pricing of the Service or any components thereof in any manner and at any time as Network Black Hole may determine in its sole and absolute discretion.

                <h2>License</h2>
                <p>
                    Unless otherwise stated, SMART NETWORKING SOLUTIONS LTD and/or it's licensors own the intellectual
                    property rights for all material on SMART NETWORKING SOLUTIONS LTD. All intellectual property rights
                    are reserved. You may view and/or print pages from
                    <a :href="`https://${constants.web_url}`">{{ constants.web_url }}</a> for your own personal use
                    subject to restrictions set in these terms of service.
                </p>
                <p>You must not:</p>
                <ol>
                    <li>
                        Republish material from <a href="/">{{ constants.web_url }}</a> and/or
                    <a :href="`https://${constants.app_url}`">{{ constants.app_url }}</a></li>
                    <li>Sell, rent or sub-license material from <a href="/">{{ constants.web_url }}</a> and/or
                    <a :href="`https://${constants.app_url}`">{{ constants.app_url }}</a></li>
                    <li>Reproduce, duplicate or copy material from <a href="/">{{ constants.web_url }}</a> and/or
                    <a :href="`https://${constants.app_url}`">{{ constants.app_url }}</a></li>
                </ol>
                <p>
                    Redistribute content from Network Black Hole (unless content is specifically made for
                    redistribution).
                </p>

                <h2>Hyperlinking to our Content</h2>
                <ol>
                    <li>
                        The following organizations may link to our Web site without prior written approval:
                        <ol>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>
                                Online directory distributors when they list us in the directory may link to our Web
                                site in the same manner as they hyperlink to the Web sites of other listed businesses;
                                and
                            </li>
                            <li>
                                Systemwide Accredited Businesses except soliciting non-profit organizations, charity
                                shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                            </li>
                        </ol>
                    </li>
                </ol>
                <ol start="2">
                    <li>
                        These organizations may link to our home page, to publications or to other Web site information
                        so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship,
                        endorsement or approval of the linking party and its products or services; and (c) fits within
                        the context of the linking party's site.
                    </li>
                    <li>We may consider and approve in our sole discretion other link requests from the following types of organizations:
                        <ol>
                            <li>commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and Consumers Union;</li>
                            <li>dot.com community sites;</li>
                            <li>associations or other groups representing charities, including charity giving sites,</li>
                            <li>online directory distributors;</li>
                            <li>internet portals;</li>
                            <li>accounting, law and consulting firms whose primary clients are businesses; and</li>
                            <li>educational institutions and trade associations.</li>
                        </ol>
                    </li>
                </ol>
                <p>
                    We will approve link requests from these organizations if we determine that: (a) the link would not
                    reflect unfavorably on us or our accredited businesses (for example, trade associations or other
                    organizations representing inherently suspect types of business, such as work-at-home opportunities,
                    shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us;
                    (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of
                    SMART NETWORKING SOLUTIONS LTD; and (d) where the link is in the context of general resource
                    information or is otherwise consistent with editorial content in a newsletter or similar product
                    furthering the mission of the organization.
                </p>

                <p>
                    These organizations may link to our home page, to publications or to other Web site information so
                    long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship,
                    endorsement or approval of the linking party and it products or services; and (c) fits within the
                    context of the linking party's site.
                </p>

                <p>
                    If you are among the organizations listed in paragraph 2 above and are interested in linking to our
                    website, you must notify us by sending an e-mail to <a :href="`mailto:info@${constants.domain}`" title="`send an email to info@{constants.domain}`">info@{{ constants.domain }}</a>. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.
                </p>

                <p>Approved organizations may hyperlink to our Web site as follows:</p>

                <ol>
                    <li>By use of our corporate name; or</li>
                    <li>By use of the uniform resource locator (Web address) being linked to; or</li>
                    <li>
                        By use of any other description of our Web site or material being linked to that makes sense
                        within the context and format of content on the linking party's site.
                    </li>
                </ol>
                <p>
                    No use of SMART NETWORKING SOLUTIONS LTD's logo or other artwork will be allowed for linking absent
                    a trademark license agreement.
                </p>

                <h2>Iframes</h2>
                <p>
                    Without prior approval and express written permission, you may not create frames around our Web
                    pages or use other techniques that alter in any way the visual presentation or appearance of our Web
                    site.
                </p>

                <h2>Reservation of Rights</h2>
                <p>
                    We reserve the right at any time and in its sole discretion to request that you remove all links or
                    any particular link to our Web site. You agree to immediately remove all links to our Web site upon
                    such request. We also reserve the right to amend these terms of service and its linking policy at
                    any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking
                    terms of service.
                </p>

                <h2>Removal of links from our website</h2>
                <p>
                    If you find any link on our Web site or any linked web site objectionable for any reason, you may
                    contact us about this. We will consider requests to remove links but will have no obligation to do
                    so or to respond directly to you.
                </p>
                <p>
                    Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its
                    completeness or accuracy; nor do we commit to ensuring that the website remains available or that
                    the material on the website is kept up to date.
                </p>

                <h2>Disclaimer</h2>
                <p>
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties and
                    conditions relating to our website and the use of this website (including, without limitation, any
                    warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of
                    reasonable care and skill). Nothing in this disclaimer will:
                </p>

                <ol>
                    <li>
                        limit or exclude our or your liability for death or personal injury resulting from negligence;
                    </li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>
                        limit any of our or your liabilities in any way that is not permitted under applicable law; or
                    </li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ol>

                <p>
                    The limitations and exclusions of liability set out in this Section and elsewhere in this
                    disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under
                    the disclaimer or in relation to the subject matter of this disclaimer, including liabilities
                    arising in contract, in tort (including negligence) and for breach of statutory duty.
                </p>
                <p>
                    To the extent that the website and the information and services on the website are provided free of
                    charge, we will not be liable for any loss or damage of any nature.
                </p>

                <h2>Credit &amp; Contact Information</h2>
                <p>
                    This Terms of service page was created at
                    <a href="https://privacyterms.io/terms-conditions-generator/">privacyterms.io terms &amp; conditions generator</a>
                    . If you have any queries regarding any of our terms, please contact us via <a :href="`mailto:info@${constants.domain}.uk`">email</a>.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useConstants } from "@/const/const.js";
const constants = useConstants();

export default {
    name: "TermsView",
    components: {},
    setup() {
        return {
            constants,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: "%s | Terms",
            link: [
                {rel: "canonical", href: `https://${constants.web_url}/terms`}
            ]
        };
    },
};
</script>
